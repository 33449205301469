<template>
  <div
    class="input-field input-field_psd"
    :class="{
      'input-field_invalid': invalid_pass,
      'input-field_focused': focused,
    }"
    @focusin="focused = true"
    @focusout="focused = false"
  >
    <label>
      <input
        class="input-field__input"
        v-model="text"
        :type="type"
        :placeholder="placeholder"
      />
    </label>
    <span class="input-field__img" v-if="eye">
      <img
        v-if="type === 'password'"
        src="@/assets/eye.svg"
        alt=""
        style="width: 18px"
        @click="change_type"
      />
      <img
        v-else
        src="@/assets/eye_close.svg"
        alt=""
        @click="change_type"
        style="width: 18px"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: "customPassField",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: String,
    eye: {
      type: Boolean,
      default: true,
    },
    rules: Array || Object,
    invalid: Boolean,
  },
  data() {
    return {
      type: "password",
      invalid_for_rule: false,
      focused: false,
    };
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("change");
        this.$nextTick(this.validate);
      },
    },

    secret() {
      return (
        this.type === "password" &&
        this.password !== "" &&
        this.placeholder === ""
      );
    },
    invalid_pass() {
      if (this.invalid) return this.invalid;
      else return this.invalid_for_rule;
    },
  },
  methods: {
    change_type() {
      if (this.type === "password") this.type = "text";
      else this.type = "password";
    },
    validate() {
      let fn = true;
      for (let key in this.rules) {
        let res = this.rules[key](this.value);
        if (typeof res === "string" || !res) {
          fn = res;
          this.invalid_for_rule = true;
          break;
        }
      }
      if (typeof fn === "string") return { valid: false, error: fn };
      else if (fn) {
        this.invalid_for_rule = false;
        return { valid: true };
      } else return { valid: false };
    },
    input(event) {
      this.$emit("input", event.target.value);
      this.$emit("change");
      this.$nextTick(() => {
        this.validate();
      });
    },
  },
};
</script>

<style scoped>
.input-field__img {
  position: absolute;
  right: 14px;
  top: 0;
  display: flex;
  width: 16px;
  height: 100%;
  align-self: center;
  cursor: pointer;
}
</style>
