<template>
  <div class="body body_login">
    <form @submit.prevent="login">
      <custom-text-field
        ref="login"
        class="login__username-field"
        v-model="username"
        :placeholder="$i18n.t('login.login_placeholder')"
        :rules="[rules.require]"
      ></custom-text-field>
      <custom-pass-field
        ref="pass"
        class="login__pass-field"
        v-model="password"
        :placeholder="$i18n.t('login.password_placeholder')"
        :rules="[rules.require]"
      ></custom-pass-field>
      <div class="login__error-text text_color_red">
        <span v-show="error">{{ error_text }}</span>
      </div>
      <custom-btn
        class="button button_theme_blue login__button"
        type="submit"
        >{{ $t("login.login") }}</custom-btn
      >
    </form>
  </div>
</template>

<script>
import customBtn from "@/components/recurringComponents/customBtn";
import CustomTextField from "@/components/recurringComponents/customTextField";
import CustomPassField from "@/components/recurringComponents/customPassField";
import rules from "@/rules";

export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      error: false,
      rules: rules,
    };
  },
  components: {
    CustomPassField,
    CustomTextField,
    customBtn,
  },
  mounted() {
    this.$auth
      .fetch()
      .then(() => {
        this.$router.push("/monitoring");
      })
      .catch(() => {
        this.$auth.logout();
      });
  },
  computed: {
    error_text() {
      return this.$i18n.t("login.error_text");
    },
  },
  methods: {
    validate() {
      this.error = false;
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.error = true;
        }
      }
    },
    login() {
      this.validate();
      if (this.error) {
        return;
      }

      this.$auth
        .login({
          data: {
            username: this.username,
            password: this.password,
          },
          fetchUser: true,
        })
        .then(() => {
          this.$store.dispatch("store_auth");
          this.$router.push("/monitoring");
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            this.error = true;
          }
          if (err?.response?.status === 500) {
            this.error = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
